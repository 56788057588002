import {
  ReportAddedPoints, ReportAdvertisementViewing,
  ReportApprovedTransactions, ReportAttendant, ReportBillings, ReportBlockedCustomers,
  ReportConcentratorError,
  ReportCoupons,
  ReportCurrentFillins,
  ReportCustomersWithoutDrawing,
  ReportDefaultPoints, ReportDetailedPoints, ReportDeviceCustomer, ReportDeviceManufacture, ReportDiscount,
  ReportDrawing,
  ReportExpirationByCustomer,
  ReportExpirationByDate, ReportExternalPartners, ReportFavoriteStation, ReportFillinByAttendant, ReportFillinByCustomer,
  ReportFillinByFuel,
  ReportFillinByStation,
  ReportFillins,
  ReportFirstFillin,
  ReportInactiveCustomers,
  ReportInventory,
  ReportMinimumStock, ReportMonthlyEvolution, ReportNewCustomers,
  ReportNewCustomersByPromoters,
  ReportNotifications, ReportPartners, ReportPartnerValues,
  ReportPaymentsByPix,
  ReportPendingTransaction,
  // ReportPoints,
  ReportPointsBalance,
  ReportPointsExpiration, ReportProducts, ReportProductsStock, ReportPurchaseSuggestion,
  ReportRatingPollGroup, ReportRealPrice,
  ReportRecurringCustomers,
  ReportRedemption, ReportRedemptionXPoints, ReportReferedCustomers,
  ReportRegisteredDiscounts, ReportRetailers, ReportSocketError,
  ReportSoldProducts, ReportStation, ReportStationRating,
  ReportSyntheticProductsStock,
  ReportTopCustomers
} from './resources/reports';

import FillinCashback from './resources/cashback/fillinCashback';
import {
  ProductAdjustment, ProductEntry, ProductTransfer, ProductWriteOff
} from './resources/inventory';
import CreatePartner from './resources/partners/CreatePartner';
import EditPartner from './resources/partners/EditPartner';
import ChangePassword from './resources/password/ChangePassword';
import AddPoints from './resources/points/AditionPoints';
import AddPointsByRefuel from './resources/points/AditionPointsByRefuel';
import DefaultPoints from './resources/points/DefaultPoints';
import DefaultPointsFuels from './resources/points/DefaultPointsFuels';
import Rules from './resources/points/SpecialRules';
import PotencialFraudRules from './resources/PotencialFraudRules/PotencialFraudRules';
import CustomerFraudIgnored from './resources/customerFraudIgnored/customerFraudIgnored';
import SpotlightProducts from './resources/spotlightProducts';
import CreateStation from './resources/stations/CreateStation';
import EditStation from './resources/stations/EditStation';

import AttendantDeletedUserList from './resources/attendantUsers/AttendantDeletedUserList';
import PlaceDeletedUserList from './resources/externalPartners/PlaceDeletedUserList';
import MapListStations from './resources/mapStations/index';
import ImportPoints from './resources/points/ImportPoints';
import ReportRegistrationCustomer from './resources/reports/ReportRegistrationCustomer';

import Dashboard from './Dashboard';
import CLientDeletedList from './resources/lgpd/ClientDeleted';
import TermsOfUseList from './resources/lgpd/TermsOfUseList';
import ReportCustomers from './resources/reports/ReportCustomers';
import DeletedUserList from './resources/users/DeletedUserList';
import AppLabelEdit from './resources/AppLabels/AppLabelEdit';
import ReportLifePoints from './resources/reports/ReportLifePoints';

export default [
  // { path: '/fuel-discounts', element: <FuelDiscountsList/> },
  // { path: '/report-points', element: <ReportPoints/> },
  { path: '/_employees/recovery', element: <PlaceDeletedUserList /> },
  { path: '/add-points', element: <AddPoints /> },
  { path: '/add-points-by-refuel', element: <AddPointsByRefuel /> },
  { path: '/attendant-employees/recovery', element: <AttendantDeletedUserList /> },
  { path: '/chains/chainId/featured-products', element: <SpotlightProducts /> },
  { path: '/chains/chainId/fillin-cashback', element: <FillinCashback /> },
  { path: '/chains/chainId/partners-create', element: <CreatePartner /> },
  { path: '/chains/chainId/partners-edit/:id', element: <EditPartner /> },
  { path: '/change-password', element: <ChangePassword /> },
  { path: '/customers-deleted', element: <CLientDeletedList /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/default-points-edit', element: <DefaultPoints /> },
  { path: '/default-points-fuels-edit', element: <DefaultPointsFuels /> },
  { path: '/employees/recovery', element: <DeletedUserList /> },
  { path: '/import-points', element: <ImportPoints /> },
  { path: '/map-stations', element: <MapListStations /> },
  { path: '/report-added-points', element: <ReportAddedPoints /> },
  { path: '/report-advertisement-viwing', element: <ReportAdvertisementViewing /> },
  { path: '/report-approved-transactions', element: <ReportApprovedTransactions /> },
  { path: '/report-attendant', element: <ReportAttendant /> },
  { path: '/report-billings', element: <ReportBillings /> },
  { path: '/report-blocked-customers', element: <ReportBlockedCustomers /> },
  { path: '/report-coupons', element: <ReportCoupons status={undefined} /> },
  { path: '/report-concentrator-error', element: <ReportConcentratorError /> },
  { path: '/report-current-fillins', element: <ReportCurrentFillins /> },
  { path: '/report-customers', element: <ReportCustomers /> },
  { path: '/report-customers-without-drawing', element: <ReportCustomersWithoutDrawing /> },
  { path: '/report-default-points', element: <ReportDefaultPoints /> },
  { path: '/report-detailed-points', element: <ReportDetailedPoints /> },
  { path: '/report-device-customer', element: <ReportDeviceCustomer /> },
  { path: '/report-device-manufacturer', element: <ReportDeviceManufacture /> },
  { path: '/report-discount', element: <ReportDiscount /> },
  { path: '/report-drawing', element: <ReportDrawing /> },
  { path: '/report-expiration-by-customer', element: <ReportExpirationByCustomer /> },
  { path: '/report-expiration-by-date', element: <ReportExpirationByDate /> },
  { path: '/report-expired-points', element: <ReportPointsExpiration /> },
  { path: '/report-external-partners', element: <ReportExternalPartners /> },
  { path: '/report-favorite-station', element: <ReportFavoriteStation /> },
  { path: '/report-fillin-by-attendant', element: <ReportFillinByAttendant /> },
  { path: '/report-fillin-by-customer', element: <ReportFillinByCustomer /> },
  { path: '/report-fillin-by-fuel', element: <ReportFillinByFuel /> },
  { path: '/report-fillin-by-station', element: <ReportFillinByStation /> },
  { path: '/report-fillins', element: <ReportFillins /> },
  { path: '/report-first-fillin', element: <ReportFirstFillin /> },
  { path: '/report-inactive-customers', element: <ReportInactiveCustomers /> },
  { path: '/report-inventory', element: <ReportInventory /> },
  { path: '/report-life-points', element: <ReportLifePoints /> },
  { path: '/report-minimum-stock', element: <ReportMinimumStock /> },
  { path: '/report-monthly-evolution', element: <ReportMonthlyEvolution /> },
  { path: '/report-new-customers', element: <ReportNewCustomers /> },
  { path: '/report-new-customers-by-promoters', element: <ReportNewCustomersByPromoters /> },
  { path: '/report-non-validated-coupons', element: <ReportCoupons status="not-redeemed" /> },
  { path: '/report-notifications', element: <ReportNotifications /> },
  { path: '/report-partner-values', element: <ReportPartnerValues /> },
  { path: '/report-partners', element: <ReportPartners /> },
  { path: '/report-payments-by-pix', element: <ReportPaymentsByPix /> },
  { path: '/report-pending-transaction', element: <ReportPendingTransaction /> },
  { path: '/report-points-balance', element: <ReportPointsBalance /> },
  { path: '/report-products', element: <ReportProducts /> },
  { path: '/report-products-stock', element: <ReportProductsStock /> },
  { path: '/report-purchase-suggestion', element: <ReportPurchaseSuggestion /> },
  { path: '/report-rating-poll-group', element: <ReportRatingPollGroup /> },
  { path: '/report-real-price', element: <ReportRealPrice /> },
  { path: '/report-recurring-customers', element: <ReportRecurringCustomers /> },
  { path: '/report-redeemed-coupons', element: <ReportCoupons status="redeemed" /> },
  { path: '/report-redemption', element: <ReportRedemption /> },
  { path: '/report-redemption-x-points', element: <ReportRedemptionXPoints /> },
  { path: '/report-refered-customers', element: <ReportReferedCustomers /> },
  { path: '/report-refunded-coupons', element: <ReportCoupons status="refunded" /> },
  { path: '/report-registered-discounts', element: <ReportRegisteredDiscounts /> },
  { path: '/report-registration-customers', element: <ReportRegistrationCustomer /> },
  { path: '/report-retailers', element: <ReportRetailers /> },
  { path: '/report-socket-error', element: <ReportSocketError /> },
  { path: '/report-sold-products', element: <ReportSoldProducts /> },
  { path: '/report-station', element: <ReportStation /> },
  { path: '/report-station-rating', element: <ReportStationRating /> },
  { path: '/report-synthetic-products-stock', element: <ReportSyntheticProductsStock /> },
  { path: '/report-top-customers', element: <ReportTopCustomers /> },
  { path: '/security-rules-edit', element: <PotencialFraudRules /> },
  { path: '/customers-fraud-ignored', element: <CustomerFraudIgnored /> },
  { path: '/special-rules', element: <Rules /> },
  { path: '/stations-create', element: <CreateStation /> },
  { path: '/stations-edit/:id', element: <EditStation /> },
  { path: '/terms-use', element: <TermsOfUseList /> },
  { path: '/warehouse/adjustment', element: <ProductAdjustment /> },
  { path: '/warehouse/product-entry', element: <ProductEntry /> },
  { path: '/warehouse/product-transfer', element: <ProductTransfer /> },
  { path: '/warehouse/product-write-off', element: <ProductWriteOff /> },
  { path: '/app-labels', element: <AppLabelEdit /> },
];
